<template>
  <div class="home" id="home">
    <div v-if="ancepe" class="salut">
      <div class="box">
        <p>Mare site de magnificu' Gorge (Some shit that I'm too lazy to translate)</p>

        <button @click="start()">Incepe (start)</button>

      </div>
    </div>
    <video autoplay id="myVideo">
      <source :src="song_src" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div class="dialog">
      <div class="pamblica">
        <div class="pamblica1"></div>
        <div class="pamblica2"></div>
      </div>
      <p>SECRET SANTA BRK</p>
      <select v-model="name" placeholder="Alege numele tau">
        <option value="" disabled selected>Alege numele tau (choose your name)</option>
        <option v-for="nume in persoane" :key="nume.Id" :value="nume.Id">{{ nume.Nume }}</option>
      </select>
      <button :disabled="name == ''" @click="pick()">Alege (Pick)</button>
    </div>

    <div v-if="response != ''" class="box-cadou">
      <div class="box-body">
        <div class="img">
          {{ response }}
        </div>
        <div class="box-lid">

          <div class="box-bowtie"></div>

        </div>
      </div>
    </div>



    <div class="controller">
      <div class="display">
        <button @click="back()">
          <unicon name="angle-left" fill="white" />
        </button>
        <button @click="stop()">
          <unicon :name="player_status" fill="white" />
        </button>
        <button @click="next()">
          <unicon name="angle-right" fill="white" />
        </button>
      </div>
    </div>
  </div>



</template>
<script>

export default {
  name: 'Home',
  components: {
  },

  data() {
    return {
      name: '',
      response: '',
      ancepe: true,
      playlist: ['brenciu', 'manea_guta', 'manea_jador', 'manea_jador_2'],
      current_song: 0,
      song_src: '',
      paused: '',
      player_status: "pause",
      persoane: [],

    }

  },
  methods: {
    start: async function () {
      this.ancepe = false;
      let video = document.getElementById('myVideo');
      video.play();
    },

    back: function () {
      let playElement = document.getElementById('myVideo');
      playElement.pause();
      this.current_song--;
      if (this.current_song < 0) {
        this.current_song = this.playlist.length - 1;
      }
      this.song_src = require(`./assets/video/${this.playlist[this.current_song]}.mp4`);
      playElement.load();
      playElement.play();
    },


    stop: async function () {
      let playElement = document.getElementById('myVideo');
      if (playElement.paused) {
        playElement.load();
        playElement.currentTime = this.paused;
        this.player_status = 'pause';
      } else {
        this.paused = playElement.currentTime;
        playElement.load();
        playElement.pause();
        this.player_status = 'play';
      }
    },


    next: async function () {
      let playElement = document.getElementById('myVideo');
      playElement.pause();
      this.current_song++;
      if (this.current_song > this.playlist.length) {
        this.current_song = 0;
      }
      this.song_src = require(`./assets/video/${this.playlist[this.current_song]}.mp4`);
      playElement.load();
      playElement.play();
    },


    loadPersoane: async function (net) {
    
        const res = await this.axios.get('https://secretsanta.georgecdn.ro/api/index.php/persoane', {});
        this.persoane = res.data.filter(pers => pers.AAles == 0);
      
    },


    pick: async function () {
      if (this.name == '') return;
      let Id = this.name;
      let formdata = new FormData();
      formdata.append('Id', Id);
      let res = await this.axios.post('https://secretsanta.georgecdn.ro/api/index.php/persoane/alege', formdata);
      this.response = res.data.Nume;
    }


  },

  mounted() {
    this.loadPersoane();
    this.song_src = require(`./assets/video/${this.playlist[this.current_song]}.mp4`);
    let playlistModal = document.getElementById('myVideo');
    let next = this.next;
    playlistModal.onended = function () {
      next();
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mountains of Christmas', cursive;

}

body {
  width: 100%;
  height: 100vh;
  font-family: 'Mountains of Christmas', cursive;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#myVideo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  vertical-align: center;
  background-color: black;
}

.dialog {
  width: 80%;
  height: 60%;
  background: rgba(255, 255, 255, 0.57);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16.5px);
  -webkit-backdrop-filter: blur(16.5px);
  z-index: 1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
  .pamblica {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .5;
    .pamblica1 {
      position: absolute;
      width: 50px;
      left: 15%;
      height: 100%;
      background: red;
    }
    .pamblica2 {
      position: absolute;
      width: 100%;
      height: 50px;
      top: 15%;
      background: red;
    }
  }

  p {
    text-align: center;
    width: 100%;
    font-size: 48px;
    font-weight: 800;
    color: rgb(213, 7, 7);
    animation: 3s ease infinite rotate;


  }

  select {
    outline: none;
    border: none;
    width: 500px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    color: red;
    border-radius: 25px;
    // padding: 10px 5px;
    appearance: none;
    z-index: 3;

  }

  input::placeholder {
    color: rgba(255, 0, 0, 0.397);

  }

  button {
    outline: none;
    border: none;
    background-color: red;
    color: white;
    padding: 12px 24px;
    border-radius: 25px;
    transition: .2s;
    z-index: 3;
  }

  button:hover {
    cursor: pointer;
    transition: .2s;

    background-color: rgb(199, 20, 20);

  }


}

.salut {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .box {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
    padding: 20px;
    width: 500px;
    height: 250px;
    color: red;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.955);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);

    p {
      width: 100%;
      font-size: 42px;
    }

    button {
      outline: none;
      border: none;
      background-color: red;
      color: white;
      padding: 12px 24px;
      border-radius: 25px;
      transition: .2s;
    }

    button:hover {
      cursor: pointer;
      transition: .2s;

      background-color: rgb(199, 20, 20);

    }
  }
}


.controller {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: red;
  margin: 0;
  padding: 0;
  animation: 3s ease infinite float;

  .display {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    border-radius: 25px;
    padding: 15px;
    gap: 30px;

    button {
      outline: none;
      border: none;
      background: transparent;
      display: flex;
      text-align: center;
      // vertical-align: center;
      padding: 0;
      width: 20px;
      height: 20px;
      justify-content: center;
      color: white;
      // align-items: center;
    }
  }

  button:hover {
    cursor: pointer;
  }
}



.box-cadou {
  position: absolute;
}

.box-cadou::before {
  content: "";
  width: 440px;
  height: 440px;
  background-color: #89cff0;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.box-body {
  z-index: 5;
  position: relative;
  height: 200px;
  width: 200px;
  // margin-top: 123.3333333333px;
  background-color: #cc231e;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(#762c2c, #ff0303);
}

.box-body .img {
  opacity: 0;
  transform: translateY(0%);
  transition: all 0.5s;
  margin: 0 auto;
  /*   display: block; */
  background: red;
  border-radius: 25px;
  font-size: 20px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.box-body:hover {
  cursor: pointer;
  -webkit-animation: box-body 1s forwards ease-in-out;
  animation: box-body 1s forwards ease-in-out;
}

.box-body:hover .img {
  opacity: 1;
  z-index: 0;
  transform: translateY(-100px);



}

.box-body:hover .box-lid {
  -webkit-animation: box-lid 1s forwards ease-in-out;
  animation: box-lid 1s forwards ease-in-out;
}

.box-body:hover .box-bowtie::before {
  -webkit-animation: box-bowtie-left 1.1s forwards ease-in-out;
  animation: box-bowtie-left 1.1s forwards ease-in-out;
}

.box-body:hover .box-bowtie::after {
  -webkit-animation: box-bowtie-right 1.1s forwards ease-in-out;
  animation: box-bowtie-right 1.1s forwards ease-in-out;
}

.box-body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#ffffff, #ffefa0)
}

.box-lid {
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 90%;
  height: 40px;
  background-color: #cc231e;
  height: 40px;
  width: 220px;
  border-radius: 5%;
  box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3);
}

.box-lid::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: linear-gradient(#ffefa0, #fff)
}

.box-bowtie {
  z-index: 1;
  height: 100%;
}

.box-bowtie::before,
.box-bowtie::after {
  content: "";
  width: 83.3333333333px;
  height: 83.3333333333px;
  border: 16.6666666667px solid white;
  border-radius: 50% 50% 0 50%;
  position: absolute;
  bottom: 99%;
  z-index: -1;
}

.box-bowtie::before {
  left: 50%;
  -webkit-transform: translateX(-100%) skew(10deg, 10deg);
  transform: translateX(-100%) skew(10deg, 10deg);
}

.box-bowtie::after {
  left: 50%;
  -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
}

@media only screen and (max-width: 768px) {
  body {
    min-height: 100vh;
  }
  .dialog {
    select {
      width: 90%;
    }
    

    button {
      width: 90%;
    }
  }

  .salut {
    .box {
      width: 90%;
      text-align: center;
      font-size: 12px;

      button {
        font-size: 14px;
        width: 100%;
      }
    }
  }

  #myVideo {
    // top: 0;
    // height: 100vh;
  }
}

@-webkit-keyframes box-lid {

  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }

  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }

  90%,
  100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}

@keyframes box-lid {

  0%,
  42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }

  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }

  90%,
  100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}

@-webkit-keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }

  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }

  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}

@keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }

  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }

  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }

  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}

@-webkit-keyframes box-bowtie-right {

  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }

  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}

@keyframes box-bowtie-right {

  0%,
  50%,
  75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }

  90%,
  100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }

  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }

  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}

@keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }

  50%,
  75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }

  90%,
  100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes float {
  0% {
    bottom: 0;
  }

  50% {
    bottom: 10px;
  }

  100% {
    bottom: 0;
  }
}
</style>
